<template lang="pug">
a-button(
  :loading="loading"
  :style="customStyle"
  :class="className"
  :type="type"
  :icon="icon"
  @click="handler"
  :ghost="ghost"
  :block="block"
  :size="size"
  :shape="shape"
  :html-type="htmlType") {{label}}
</template>
<script>
export default {
  name: "AppButton",
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: ""
    },
    handler: {
      type: Function,
      default: () => {}
    },
    icon: {
      type: String,
      default: ""
    },
    customStyle: {
      type: Object,
      default: () => {}
    },
    ghost: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "default"
    },
    className: {
      type: String,
      default: ""
    },
    shape: {
      type: String,
      default: null
    },
    block: {
      type: Boolean,
      default: false
    },
    htmlType: {
      type: String,
      default: null
    }
  }
};
</script>
<style lang="less">
//Import globals styles
@import "../../../less/main.less";

.ant-btn-primary {
  background-color: var(--primary-color) !important;
  color: var(--white-color) !important;
  border: none !important;
}
.block {
  font-size: 18px !important;
  letter-spacing: 1px !important;
  width: 357px !important;
  height: 46px !important;
}

.medium {
  width: 180px;
  height: 50px;
}

.small {
  width: 160px;
  height: 35px;
}

.info {
  background: var(--info-color) !important;
  border: var(--info-color) !important;
  color: var(--white-color) !important;
}

.cancel {
  background: var(--error-color) !important;
  border: var(--error-color) !important;
  color: var(--white-color) !important;
}
</style>
