<template lang="pug">
p(
  :class="className"
  :style="customStyle"
  @click="onClick"
  ) {{content}}
</template>
<script>
export default {
  name: "AppText",
  props: {
    content: {
      type: String,
      default: ""
    },
    className: {
      type: String,
      default: ""
    },
    customStyle: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    onClick(ev) {
      this.$emit("click", ev);
    }
  }
};
</script>
<style lang="less" scoped>
//Import globals styles
@import "../../../less/main.less";

.regular() {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  margin: 0;
  display: inline;
}

.regular {
  .regular();
  &-spaced {
    .regular();
    letter-spacing: 0.1em;
    &-light {
      .regular();
      letter-spacing: 0.1em;
      color: var(--white-color);
    }
  }
  &-bold {
    .regular();
    font-weight: 600;
  }
  &-light {
    .regular();
    color: var(--white-color);
  }
  &-error {
    .regular();
    color: var(--error-color);
  }
}

.small() {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: var(--black-color);
  display: inline;
  margin: 0;
}

.small {
  .small();
  &-light {
    .small();
    color: var(--white-color);
  }
  &-error {
    .small();
    color: var(--error-color);
  }
}

.link {
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}

.nano() {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  display: inline;
}

.nano {
  .nano();
  &-light {
    .nano();
    color: var(--white-color);
  }
}

.text-info {
  color: var(--info-color) !important;
}

.text-gray {
  color: var(--gray-color) !important;
}

.text-primary {
  color: var(--primary-color) !important;
}

.text-error {
  color: var(--error-color) !important;
}
</style>
