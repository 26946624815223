<template lang="pug">
a-layout(
  :class="className"
  :style="customStyle")
  slot
</template>
<script>
export default {
  name: "Layout",
  props: {
    className: {
      type: String,
      default: ""
    },
    customStyle: {
      type: Object,
      default: () => {}
    }
  }
};
</script>
<style lang="less" scoped>
//Import globals styles
@import "../../../less/main.less";
</style>
