<template lang="pug">
h2(
  :class="className"
  :style="customStyle") {{content}}
</template>
<script>
export default {
  name: "AppH2",
  props: {
    content: {
      type: String,
      default: ""
    },
    className: {
      type: String,
      default: ""
    },
    customStyle: {
      type: Object,
      default: () => {}
    }
  }
};
</script>
<style lang="less" scoped>
//Import globals styles
@import "../../../less/main.less";
.regular() {
  font-weight: regular;
  font-size: 20px;
  line-height: 200%;
  display: inline;
  letter-spacing: 1px;
  margin: 0;
}

.regular {
  &-light {
    .regular();
    color: var(--white-color);
  }
  &-color {
    .regular();
    color: var(--primary-color);
  }
  &-container {
    .regular();
    color: var(--container-text-color);
  }
}
</style>
