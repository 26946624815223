<template lang="pug">
  app-image(
    v-if="mode=='login'"
    className="logo"
    :customStyle="{width:'100%' ,margin: '70px 0 30px'}"
    :url="rederedLogo")
  div(
    v-else-if="mode=='header'"
    :style="{display:'flex',height:'100%',alingItems:'center'}")
    app-image(
    className="logo"
    :customStyle="{ maxHeight:'60px'}"
    :url="rederedLogo")
  div(:class="className"
      v-else
      :style="{height:'95%',width:'95%', display:'grid', placeItems:'center',padding:'0px',margin:'0px 5px',...customStyle}")
    app-image(
    className="logo"
    :customStyle="{maxHeight:'60px'}"
    :url="rederedLogo")
</template>

<script>
import mirageLogo from "@/assets/logos/mirage.jpeg";
import artLogo from "@/assets/logos/art.png";
import logoEmc from "@/assets/logos/emctiles.jpeg";

import { mapGetters } from "vuex";
//Import atoms components
import AppImage from "@/components/atoms/AppImage.vue";
//color service
import colorService from "@/common/color.service";
import { THEME } from "@/store/actions.type";

export default {
  name: "Logo",
  components: {
    AppImage
  },
  props: {
    mode: {
      type: String,
      default: ""
    },
    className: {
      type: String,
      default: ""
    },
    customStyle: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      artLogo,
      mirageLogo,
      logoEmc
    };
  },
  computed: {
    ...mapGetters(["getThemeName"]),
    rederedLogo() {
      colorService.changeTheme(this.getThemeName);
      switch (this.getThemeName) {
        case "stn":
          return this.artLogo;
        case "alaplana":
          return this.mirageLogo;
        case "keratile":
          return logoEmc;
        default:
          return this.artLogo;
      }
    }
  },
  mounted() {
    this.$store.dispatch(THEME, colorService.getTheme());
    colorService.changeTheme(colorService.getTheme());
  }
};
</script>
