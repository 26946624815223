<template lang="pug">
  div
    a-spin(v-if="!loaded" class="image-loader" size="large")
    img(
      v-show="loaded"
      @load = "onImgLoad"
      :class="className"
      :src="url"
      :style="customStyle")
</template>
<script>
export default {
  name: "AppImage",
  props: {
    url: {
      type: String,
      required: true
    },
    className: {
      type: String,
      default: ""
    },
    customStyle: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      loaded: false
    };
  },
  methods: {
    onImgLoad() {
      this.loaded = true;
    }
  }
};
</script>
<style lang="less" scoped>
//Import globals styles
@import "../../../less/main.less";
.logo {
  min-height: 50px;
  max-width: 216px;
  object-fit: cover;
}
.image-loader {
  width: 100%;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sidebar-image {
  width: 100%;
  object-fit: cover;
  height: 111.13px;
}

.carousel-image {
  width: 249.97px;
  object-fit: cover;
  height: 122.22px;
}

.input-search-image {
  width: 191.78px;
  height: 118px;
  display: flex;
}
</style>
